.orderContainer {
  margin-top: 20px;
  padding: 10px 20px;
  border-bottom: 1px solid #e4e4e4;
  z-index: 10;
  width: 100%;
  background: #f0f2f5;

  .footerBox {
    background: #fff;
    padding: 10px;
    border-bottom: 2px solid #f0f2f5;

    span {
      margin-right: 20px;
    }
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
}
@primary-color: #3875FE;