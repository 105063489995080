.invoiceInfo {
    width: 100%;
    background-color: #ffe6e6;
    border: 1px dashed red;
    margin-bottom: 16px;
    padding: 8px;
    line-height: 25px;
}
.formBox {
    border-top: 1px dashed #e8e8e8;
    padding-top: 20px;
}
.ocrTitle {
    font-weight: 700;
    color: #1890ff;
}
.ant-modal:not(:has(.monaco-editor)) div[aria-hidden='true'] {
    display: none !important;
}

@primary-color: #3875FE;