.title {
    font-size: 14px;
    font-weight: 700;
}
.foooterBtn {
    display: flex;
    justify-content: flex-end;
}
.btnItem {
    margin-right: 20px;
}

@primary-color: #3875FE;