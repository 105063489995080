.paymentBox {
    margin: 28px 18px 0;
    height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    font-size: 14px;
    .paymentTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5px;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 5px;
        align-items: center;
        .payIcon {
            display: flex;
        }
        .paymentType1 {
            color: #fff;
            padding: 2px 10px;
            font-size: 12px;
            background: rgba(74, 198, 114, 0.5);
            border-radius: 6px;
        }
        .paymentType {
            color: #fff;
            padding: 2px 10px;
            font-size: 12px;
            // background: #2a61d6;
            background: rgba(42, 97, 214, 0.5);
            border-radius: 6px;
            margin-right: 5px;
        }
    }
    .paymentBody {
        padding-bottom: 5px;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 5px;
        .paymentAmount {
            font-size: 20px;
            line-height: 30px;
            height: 30px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                margin-left: 5px;
                color: #909399;
                font-size: 16px;
            }
        }
        .paymentInfo {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            color: #909399;
            div {
                display: flex;
                span {
                    margin-right: 5px;
                }
            }
        }
    }
    .paymentFooter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: #909399;
        .payMiceType {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 20%;
        }
        .paySuppler {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 17%;
        }
    }
    .paymentCard.active {
        border-color: #2a61d6;
        .paymentTitle {
            .payIcon {
                display: flex;
            }
            .paymentType1 {
                background: #4ac672;
            }
            .paymentType {
                background: #2a61d6;
            }
        }
    }
}

@primary-color: #3875FE;