.logo {
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 170px;
    height: auto;
  }

  .miniLogo {
    width: 60px;
  }

  span {
    color: #FFF;
    font-size: 18px;
    margin-left: 10px;
    // min-width: 80px;
  }
}

// .menuBox .ant-tooltip-inner {
//   color: black !important;
// }
// 全局样式修改
.cdk-overlay-container .ant-tooltip-inner{
  color: #ffffff !important;
}
.tip {
  width: 100%;
}

// .ant-menu-submenu-title {
//   padding-left: 20px !important;
// }

// .ant-menu-item {
//   padding-left: 20px !important;
// }

@primary-color: #3875FE;