.timeBox{
    margin: 0 auto;
    text-align: center;
    padding: 10%;
    font-size: 24px;
    span{
        display: block;
        font-size: 80px;
        margin-bottom: 20px;
    }
}
@primary-color: #3875FE;