.module_content {
    margin: 8px 0 0 0;
    border: 1px solid #1890ff;
    border-radius: 4px;
    padding: 5px;
}
.module_title {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    margin: 10px 0;
}

@primary-color: #3875FE;