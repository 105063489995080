.modalContent {
  :global{
    .anticon svg{
      width: 1.4em;
      height: 1.4em;
    }
    .ant-modal-body {
      padding: 30px 0 0 0 !important;
      iframe{
        border: none;
      }
  } 
  }
}

@primary-color: #3875FE;