.header_left {
    .img {
        height: 60px;
        width: 11rem;
    }
}

.header_right {
    display: flex;
    align-items: center;
    height: 100%;

    .search {
        display: flex;
        align-items: center;

        .search_icon {
            font-size: 22px;
            color: #fff;
        }
    }

    .questIcon {
        color: #fff;
        font-size: 22px;
        margin: 0 20px;
    }

    .badge {
        height: 100%;
        margin-right: 20px;
        display: flex;
        justify-content: center;

        .ant-dropdown-trigger {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .bellIcon {
            color: #fff;
            font-size: 22px;
        }
    }

    .owner {
        display: flex;
        align-items: center;
        height: 100%;

        .ant-dropdown-trigger {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .userName {
                color: #fff;
                margin-left: 2px;
            }
        }

    }

}
.info {
    background-color: #fff;
    min-width: 400px;
    border-radius: 4px;

    .ant-tabs-nav-wrap {
        justify-content: center;
    }
}

.menu {
    min-width: 140px;

    .menu_item {
        font-size: 14px;
        color: rgb(85, 84, 84);

        .icon {
            margin-right: 5px;
        }
        .grayColor{
            color: rgb(85, 84, 84);
        }
    }
}
.verify_pwd_Box{
    width: 70%;
    margin-left: 25%;
    margin-bottom: 30px;
    p{
        margin-bottom: 8px;
    }
    div{
        color: #999;
        .anticon{
            margin-right: 10px;
        }
    }
    .isVerify{
        color: #49c571;
    }
}
@primary-color: #3875FE;