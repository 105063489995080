.info_center {
  border-top: 1px dashed #ccc;
}
.boderRow {
  position: relative;
}
.statusTxt {
  position: absolute;
  top: 0;
  right: 0;
  background: #1890ff;
  color: #fff;
  padding: 10px 20px;
}
.amount {
  text-align: right;
  margin-top: 20px;
  font-weight: 500;
  font-size: 1.2rem;
  span {
    color: #1890ff;
  }
}
.carTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card_title {
  margin-bottom: 20px;
}
.btns {
  .btn {
    line-height: 32px;
    height: 32px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    margin-left: 8px;
    touch-action: manipulation;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
  }
}
.container {
  .searchBox {
    margin-bottom: 8px;
  }
  .mapContent {
    width: 100%;
    height: 350px;
  }
}
p.driversInfo {
  display: block;
  width: 100%;
  margin: auto;
}

@primary-color: #3875FE;