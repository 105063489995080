.insight-box {
  padding: 15px;
  box-sizing: border-box;
  margin: 0 18px 20px;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  overflow-x: auto;
  display: flex;

  .insight-item {
    display: block;
    min-width: 18%;
    box-sizing: content-box;
    flex-shrink: 0;
    list-style: none;
    text-align: center;
    border-right: 1px solid #f0f0f0;

    .item-title {
      margin-bottom: 4px;
      color: rgba(0, 0, 0, .45);
      font-size: 14px;
      line-height: 22px;
    }

    .item-desc {
      color: rgba(0, 0, 0, .85);
      font-size: 24px;
      line-height: 32px;
    }
  }

  .insight-item:last-child {
    border: none;
  }

  .noData {
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px;
  }
}
@primary-color: #3875FE;