.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
}

.sider_content {
    .site-layout-background {
        background: #f0f2f5;
    }

    .content {
        padding: 10px;
        overflow: auto;
    }

    .sider {
        box-shadow: 2px 0 8px 0 rgb(222 223 221);
        z-index: 9;
        background-color: #fff;
        overflow-y: hidden;

        .ant-layout-sider-children {
            height: 99%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .menuListBox {
                height: calc(100vh - 106px);
                overflow-y: auto;
            }
            .collIcon {
                text-align: center;
                border-top: 1px solid rgb(233, 229, 229);
                height: 40px;
                line-height: 40px;

                .trigger {
                    font-size: 20px;
                }
            }
        }
    }
}

.ant-layout {
    height: 100%;

    .ant-layout-header {
        background-color: #001529;
        padding: 0 40px 0 0px;
    }

    // 覆盖checkbox带+号样式错乱
    .ant-table-row-expand-icon:after,
    .ant-table-row-expand-icon:before {
        position: unset;
        background-color: unset;
        transition: unset;
    }

    .ant-breadcrumb {
        font-size: 14px;
        padding-left: 10px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        box-shadow: 0px 2px 1px #e1dee1;
    }

    .ant-layout-footer {
        padding: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        // border-top: 1px solid rgb(233, 229, 229);
        // box-shadow:inset 0px 15px 15px -15px rgb(223, 221, 221),
        // inset 0px -15px 15px -15px rgb(231, 228, 228);
    }

    // 滚动条
    // ::-webkit-scrollbar {
    //   /*滚动条整体样式*/
    //   width: 4px;
    //   /*高宽分别对应横竖滚动条的尺寸*/
    //   height: 4px;
    //   cursor: pointer !important;
    // }

    // ::-webkit-scrollbar-thumb {
    //   background: #b6b3b3;
    //   border-width: 2px;
    //   box-shadow: inset 0 0 5px rgba(131, 130, 130, 0.2);
    //   background-clip: padding-box;
    // }

    // ::-webkit-scrollbar-track {
    //   /*滚动条里面轨道*/
    //   background: #ffffff;
    // }

    // ::-webkit-scrollbar-thumb:hover {
    //   background: rgba(100, 100, 100, 0.7);
    // }
    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        border-style: dashed;
        border-color: transparent;
        border-width: 1px;
        background-color: rgba(172, 173, 175, 0.4);
        background-clip: padding-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgba(105, 106, 107, 0.7);
    }
}
.siderSty {
    overflow-y: scroll;
    height: 500px !important;
}

@primary-color: #3875FE;