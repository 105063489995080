body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
lengend,
label,
button,
input,
select,
option,
textarea,
optgroup,
table,
thead,
tbody,
tfoot,
tr,
th,
td,
div,
span,
img,
a,
em,
i,
iframe,
:before,
:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
button,
input,
select,
option,
textarea,
optgroup,
img {
  // font: 14px/1 "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC",
  //   "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC",
    "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  outline: none;
  border: none;
}

textarea {
  resize: none;
  overflow: auto;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main,
details,
menu {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}


body .ant-drawer-close {
  position: absolute;
  right: 0;
}
body {
  height: 100%;
}
#root {
  height: 100%;
  // overflow: hidden;
}
.ant-dropdown{
  z-index: 1000 !important;
}
@primary-color: #3875FE;