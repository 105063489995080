.file-viewer {
  display: flex;
  .ml-10 {
    margin-left: 10px;
  }
  .file-view-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    .item-img {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10%;
      }
    }
    .item-icon {
      font-size: 18px;
    }
    .item-title {
      margin-left: 10px;
      color: #09f;
      // font-size: 16px;
    }
  }
}
@primary-color: #3875FE;