.boderRow {
  // display: flex;
  // align-items: center;
  border: 1px solid #8b8b8b;
  padding: 10px;
  margin-bottom: 10px;
}
.rowStyle {
  display: flex;
  align-items: center;
}
.borderCol {
  border: 1px solid #2db7f5;
  text-align: center;
  color: #2db7f5;
  font-size: 14px;
  margin-right: 10px;
  padding: 4px 8px;
}
.title {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.Num {
  font-size: 14px;
  color: #c5c2c2;
}
.info_center {
  border-top: 1px solid #141415;
  margin-top: 10px;
  padding-top: 10px;
  .ant-descriptions-item-container{
    align-items: flex-start;
    .ant-descriptions-item-content{
      align-items: center;
      justify-content: flex-start;
      white-space: pre-wrap;
      .ant-btn-link{
        padding: 0 3px;
        line-height: 1;
        height: 16px;
      }
    }
  }
}
.card_title {
  font-size: 20px;
  font-weight: 700;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
.dangerBtn {
  margin-left: 10px;
  background-color: #d9001b !important;
  color: #fff !important;
}
.wariningBtn {
  margin-left: 10px;
  background-color: #f59a23 !important;
  color: #fff !important;
}
.confirmBtn {
  margin-left: 10px;
  background-color: #02a7f0 !important;
  color: #fff !important;
}

.hotelDetailsRow {
  text-align: right;
}
.HotelDetailsTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hotel-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    color: #1869cd;
    margin-bottom: 0;
  }
}
.hotel-box {
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px dashed #ccc;
}
.quoteAmount {
  font-weight: bold;
}
.mopDetails {
  p {
    margin-bottom: 10px;
  }
}
.orderTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap:  20px;
  p {
    margin-bottom: auto;
  }
}
.card_titleBox {
  p {
    margin-bottom: auto;
  }
}
.venueQuoteTips {
  background: #e7edf9;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 10px;
}
.formItemBox {
  >div{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:  20px;
    margin-bottom: 10px;
  }
  .ant-form-item {
    margin-bottom: auto !important;
  }
}
.qrcode-modal-content {
  padding-top: 10px;
  text-align: center;
  .code_box {
    position: relative;
    height: 300px;
  }
  .share-btn {
    margin-top: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      color: #3875fe;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
.mopDetails .ant-table-thead > tr > th {
  background: #e1e1e1;
}
.mopDetails .hotel-details .ant-table-content{
  //border: 1px solid red;
  &::-webkit-scrollbar{
    height: 12px;
    width: 80px;

  }
}
p.orderNum {
  font-size: 16px;
  font-weight: 800;
}

p.statusTxt {
  font-size: 12px;
  background: #fff;
  padding: 2px 5px;
  color: #1890ff;
}

p.orderStatus {
  background: #e7edf9;
  padding: 2px 10px;
  font-size: 12px;
  color: #1890ff;
  border: 1px solid #1890ff;
  border-radius: 5px;
}
p.orderStatusNo {
  padding: 2px 10px;
  font-size: 12px;
  color: #7b7878;
  border-radius: 5px;
}
.orderBox {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #1890ff;
}
.audit_module{
  padding: 10px;
  border: 1px solid #666;
  margin-bottom: 10px;
  .module_name{
    font-size: 20px;
    font-weight: 800;
    padding-bottom: 18px;
    padding-top: 8px;
    border-bottom: 1px solid;
    margin-bottom: 18px;
  }
  .ant-form-item-control-input-content{
    //background-color: #4ac672;
    width: 100%;
    .place_icon{
      &:hover{
        cursor: pointer;
      }
    }
    .ant-row{
      width: 100%;
      .inline_compact{
        display: flex;
        align-items: center;
      }
      .hint{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: red;
        &:hover{
          cursor: pointer;
        }
        .iconfont{
          margin-right: 5px;
          //color: red;
        }
      }
      .hint_green {
        color: green;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  .label_wrap__form {
    label{
      text-wrap: wrap;
    }
  }
}

@primary-color: #3875FE;