.system-config-container {
    padding: 24px;

    .ant-form {
        max-width: 800px;
    }
    .ant-form-item{
        margin-bottom: 14px;
    }
    .category-container {
        margin-bottom: 24px;
        .category-item {
            margin-bottom: 0;

            .ant-form-item-control-input {
                min-height: 0;
                margin-bottom: 10px;
            }
        }

        .category-input {
            display: flex;
            gap: 8px;
            align-items: flex-start;
        }
    }

    .time-config {
        .ant-input-number {
            width: 100px;
            margin: 0 8px;
        }
    }

    .ant-radio-group {
        .ant-input-number {
            width: 100px;
            margin: 0 8px;
        }
    }
}

@primary-color: #3875FE;