.smartGalleryBox {
    background-color: #fff;
    margin: 20px;
    padding: 20px;
    overflow: hidden;
    .leftMenu {
        float: left;
    }
    .rightBox {
        float: right;
        width: calc(100% - 150px);
    }
    .fuillBox{
        width: 100%;
    }
    .imgP {
        width: 100%;
        height: 58%;
        margin-bottom: 8px;
        border-radius: 8px;
        display: inline-block;
    }

    .gallery-search-form {
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 30px;

        .ant-form-item {
            margin-bottom: 30px;
        }
        .uploadBox{
            width: 104px;
            height: 104px;
            margin-right: 8px;
            margin-bottom: 8px;
            text-align: center;
            vertical-align: top;
            background-color: #fafafa;
            border: 1px dashed #d9d9d9;
            border-radius: 2px;
            cursor: pointer;
            transition: border-color .3s;
            padding-top: 32px;
            .upladeTxt{
                margin-top: 6px;
            }
        }
    }
    .menuClassName {
        .ant-menu-item {
            padding-left: 10px !important;
        }
    }

}

.scrollUl {
    height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;
    .titleP {
        margin-top: 18px;
        margin-bottom: 7px;
    }
    .loadMore {
        display: block;
        width: 100%;
        float: left;
        text-align: center;
    }
}
.checkNum {
    .btnBox {
        display: inline-block;
    }
}
.imgItem {
    width: 140px;
    height: 260px;
    float: left;
    margin: 10px;
    position: relative;
    // border-radius: 8px;
    overflow: hidden;
    .ant-checkbox-wrapper {
        display: none;
    }
    .ant-checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        // display: none;
    }

    .dropdownBtn {
        display: none;
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        z-index: 9;
        color: #fff;
        background: #cfcfcf;
        padding: 0 6px;
        border-radius: 6px;
    }

    p {
        text-align: center;
        white-space: nowrap;
        font-size: 12px;
        color: #bcbbbb;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        margin-bottom: 0px;
    }

    .item-icon {
        font-size: 140px;
    }
    .icon-eml{
        font-size: 120px;
    }
    
}

.imgItem:hover {
    .imgP {
        border: 2px solid #1f60bd;
    }

    // .ant-checkbox {
    //     display: block;
    // }
    .dropdownBtn {
        display: block;
    }
}
.ant-modal:not(:has(.monaco-editor)) div[aria-hidden='true'] {
    display: none !important;
}
iframe {
    border: none;
}
.remakeRecognitionMain {
    width: 100%;
    overflow: hidden;
    .remakeRecognitionRightBox {
        width: 70%;
        float: left;
        .ant-form,
        .remakeRecognitionResault {
            border: 1px solid #e9e9e9;
            padding: 16px;
            margin-top: 10px;
            .remakeRecognitionResultTitle{
                font-size: 18px;
            }
        }
        .tips{
            padding-left: 18px;
            color: rgb(246, 65, 65);
            p{
                margin-bottom: 0;
                padding-bottom: 0;
            }
           
        }
    }
    .remakeRecognitionLeftBox {
        width: 27%;
        float: right;
        box-sizing: border-box;
        border: 1px solid #e9e9e9;
        height: 100%;
        padding: 16px;
        margin-left: 20px;
        height: 400px;
        overflow-y: scroll;
    }
    .remakeRecognitionLeftBoxCollapsed {
        float: right;
    }
}
.modalPreview{
    position: relative;
    .fullScreenBtn{
        position: absolute;
        top: 12px;
        right: 40px;
    }
}
@primary-color: #3875FE;