.accountSettingsBox{
    background: #fff;
    padding-bottom: 40px;
    margin: 16px;
    margin-top: 30px;
    padding-top: 20px;
    .ant-tabs-tab-active{
        background-color: #e6f7ff;
    }
}
@primary-color: #3875FE;