.fundingSummaryBox{
    .fundingSummaryTitleBox{
        background: #fff;
        margin-top: 4px;
        padding: 12px;
        overflow: hidden;
        .titleLeftBox{
            width: 50%;
            float: left;
            p{
                font-size: 20px;
                margin-bottom: 0px;
            }
            span{
                color: #999;
                font-size: 10px;
            }
        }
       .titleRightBox{
        float: right;
       }
    }
    .fundingSummaryContentBox{
        overflow: hidden;
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        .statisticBox{
            box-sizing: border-box;
            padding: 12px;
            background: #fff;
            margin-bottom: 12px;
            min-height: 120px;
            box-shadow: 0 0 13px 2px #f3f3f3;
            border-radius: 7px;
            position: relative;
            cursor: pointer;
            p{
                z-index: 9;
                position: relative;
            }
            .ant-btn{
                display: block;
                cursor: pointer;
            }
            .num{
                font-size: 2rem;
            }
            img{
                position: absolute;
                right: 0;
            }
            .summaryBg{
                top: 12%;
                width: 48%;
            }
            .summaryIcon{
                width: 13%;
                right: 8%;
                bottom: 12%;
                float: right;
                font-size: 2rem;
            }
            .goIcon{
                position: absolute;
            }
        }
        .redColor{
            color: red;
        }
    }
}

@primary-color: #3875FE;