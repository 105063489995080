.ant-modal-body {
    .fileListItemView {
        display: grid;
        grid-template-columns: 46px auto;
        align-items: center;
        border-bottom: 1px solid #dedede;
        padding: 10px 0;

        > img {
            width: 30px;
            height: auto;
        }
    }

    .fileIcon {
        font-size: 30px;
    }
}

.tableType_tableDetails {
    font-size: 14px;
}

.detailTips {
    padding: 0 18px;
}

.iqueryTitle {
    text-align: center;
    background: #1990ff;
    color: #fff;
    padding: 0.6rem 0;

    p {
        margin-bottom: 0;
    }
}

.iqueryDetail {
    .search-filter-areabox {
        .extend-option {
            background: #eee;
        }

        .simple-filter-wrap {
            float: none;
            display: block;
            margin-bottom: 20px;

            .rightBtnBox {
                float: none;

                button {
                    margin-left: 0;
                }
            }
        }
    }
}

.customer-menu .ant-dropdown-menu-title-content .ant-upload span:last-child {
    margin-left: 0;
    color: #909399;
}

.ant-dropdown-menu-item:hover
    .ant-dropdown-menu-title-content
    .ant-upload
    span {
    color: #13161a;
}

.titleP {
    margin-bottom: 20px;
    color: #999;
}

.images_drawer {
    .container {
        padding: 10px 20px;
        margin: 10px 0;
        width: 100%;
        border: 1px dashed #f2f2f2;
        border-radius: 6px;

        .name {
            font-size: 16px;
            font-weight: 600;
            color: #333;

            //display: flex;
            //align-items: flex-start;
            //flex-direction: column;
            //justify-content: flex-start;
            .sub_header {
                font-size: 14px;
                color: #999;
            }
        }

        .address {
            font-size: 14px;
            font-color: #999;
            margin: 10px 0;
        }

        .column_title {
            font-size: 16px;
            font-weight: 600;
            color: #333;

            .hint {
                font-size: 14px;
                color: #999;
                font-weight: normal;
            }
        }
    }

    .download {
        color: rgba(25, 144, 255, 0.71);
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;

        &:hover {
            color: #1990ff;
            cursor: pointer;
        }
    }
}

.messageChatBox {
    p {
        margin-bottom: 0;
        font-size: 13px;
    }
}
.payDetail {
    .detailsBox {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
.queryModalBox{
    .ant-form-item {
      margin-bottom: 0 !important;
  }
  }
@primary-color: #3875FE;