.content {
  height: 100%;
  margin-top: 28px;
  margin: 28px 18px 0 18px;
  background: #ffffff;
  padding: 20px;
  h1 {
    font-size: 16px;
    font-weight: 700;
  }
}
.scanBtn {
  margin-right: 20px;
}
p {
  font-size: 16px;
  margin-bottom:30px ;
}
.rightModule {
  // margin-top: 0px;
}
.rightBtns {
  margin-top: 30px;
}
.configInfo {
  color: rgb(86, 173, 20);
}
.configLabel {
  font-weight: 700;
}
.tooltip {
  color: #ffffff;
}
.rightRow {
  margin-right: 80px;
}
@primary-color: #3875FE;