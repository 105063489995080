

// 修改消息提示调大
.ant-message-notice-content {
  padding: 16px 20px;
  font-size: 16px;
}

//自定义副标题
.sub-title {
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0 10px 0;
  padding-left: 8px;
  border-left: 4px solid #3875FE;

  &.drawer-type {
    margin: 0;
  }
}

.ant-drawer-body {
  padding: 16px 24px;
}

.form-item-tips {
  color: #C0C4CC;
  font-size: 12px;
  margin: -5px 5px 20px 5px
}

//自定义下拉框样式
.customer-menu {
  .ant-dropdown-menu-title-content {
    color: #909399;

    span:last-child {
      margin-left: 10px;
    }
  }

  .ant-dropdown-menu-item {
    position: relative;
    padding: 10px 15px;
  }

  .ant-dropdown-menu-item:hover {
    &::before {
      position: absolute;
      display: block;
      content: '';
      height: 100%;
      left: 0;
      top: 0;
      width: 3px;
      background-color: #7CBADE;
    }

    .anticon,
    .x-icon-font {
      color: #3875FE;
    }

    .ant-dropdown-menu-title-content {
      color: #13161A;
    }
  }
}

//模态框自定义标题样式
.modal-sub-title {
  font-size: 18px;
  color: #13161A;
  font-weight: 600;
  position: relative;
  padding-bottom: 12px;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #3875FE;
    left: 0;
    bottom: 0;
  }
}

//抽屉样式
.customer-drawer {
  .action-btn {
    .ant-btn {
      width: 50%;
      max-width: 120px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .customer-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ant-btn {
      margin-right: 20px;
      width: 100px;
    }
  }
}

.iqueryBox {
  margin-top: 28px;

  .search-filter-areabox {
    border: none;
  }
}

@primary-color: #3875FE;