.phoneBox {
    .maskBox {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 999;
        left: 0;
    }
    #phone {
        width: 350px;
        height: 650px;
        position: fixed;
        background: #fff;
        z-index: 1000;
        top: 36%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2E2E2E;
        border: 10px solid #3B3B3B;
        margin: 100px auto;
        border-radius: 30px;
        /*设置div元素的圆角边框*/
    }

    #camera {
        width: 8px;
        height: 8px;
        background-color: #1A1A1A;
        border-radius: 50%;
        border: 2px solid #505050;
        margin: 0px auto;
        margin-bottom: 4px;
        /*距离上边距10px 左右居中*/
    }

    #receiver {
        width: 80px;
        height: 4px;
        border: 2px solid #505050;
        margin: 2px auto;
        border-radius: 10px;
        background-color: #1A1A1A;
    }

    #screen {
        width: 326px;
        height: 583px;
        background-color: #fff;
        border: 3px solid #1C1C1C;
        margin: 10px auto;
        iframe{
            border: none;
        }
    }

    #btn {
        width: 20px;
        height: 20px;
        background: #1A1A1A;
        border-radius: 50%;
        /*当宽高相同时，则为圆*/
        margin: -10px auto;
        cursor: pointer;
    }

    /*:before 选择器在被选元素的内容前面插入内容。*/
    #btn:before {
        width: 16px;
        height: 16px;
        border: 2px solid white;
        border-radius: 30%;
        content: "";
        /*即使插入的内容为空也需要写，否则不显示*/
        display: inline-block;
        margin-top: 7px;
        margin-left: 7px;
    }
}


@primary-color: #3875FE;