.customerDetail{
    margin-bottom: 30px;
    p{
        margin: 0;
    }
    max-height: 520px;
    max-width: 600px;
    .headerBox {
        background: white;
        z-index: 1;
        height: 80px;
        .searchBox {
            float: left;
            display: flex;
            input {
                width: 500px;
                text-align: left;
                margin-left: .1rem;
                border-radius: .06rem;
                box-sizing: border-box;
                height: 35px;
            }

            p {
                width: 80px!important;
                height: 35px;
                line-height: 35px;
                text-align: center;
                display: block;
                width: 20%;
                color: #fff;
                background-color: #15717D;
                border-color: #15717D;
                margin-left: 20px;
                border-radius: 4px;
                box-shadow: 0 2px 6px 0 rgba(57,175,209,.5);
            }

        }
    }
    .msg_list::-webkit-scrollbar{
        display: none;
    }
    .msg_list{
        overflow-y: scroll;
        max-height: 310px;
        margin-bottom: 20px;
        .msg_item{
            display: flex;
            margin: 10px;
            .head{
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }
            .chat{
                display: flex;
                flex-direction: row;
                .msg{
                    width: 100%;
                    padding: 0 10px 0 10px;
                    .audio_box{
                        width: 100%;
                        .audio{
                            width: 60vw;
                        }

                    }
                    p{
                        text-align: left;
                        word-break: break-all;
                        word-wrap: break-word;
                    }
                    .name{
                        font-weight: 700;
                    }
                }
            }
        }

    }
    .choseInput{
        width: 80%;
    }
    .bottomBox{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        .ant-btn{
            margin-left: 10px;
        }
    }
}
.compBox{
    p{
        margin-bottom: 16px;
        .labelTxt{
            display: block;
            float: left;
            width: 16%;
        }
    }
}
@primary-color: #3875FE;