.appSimulatorBox{
    margin: 20px;
    overflow: hidden;
    .appForm{
        width: 30%;
        float: left;
        .appSibtn{
            float: right;
        }
    }
}
@primary-color: #3875FE;