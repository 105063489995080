.smartPayOrderInfo{
    overflow: hidden;
    span{
        float: left;
        width: 50%;
        display: block;
    }
    p{
        width: 100%;
        float: left;
        margin-bottom: 0;
    }
}
@primary-color: #3875FE;