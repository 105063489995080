.title {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 0;
}

.custom-form-file-view {
  display: flex;
  .view-item {
    width: 50px;
    height: 50px;
    border-radius: 5%;
    margin-right: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}
.openIcon {
  color: red;
  border: 1px solid #eee;
}
.colClass {
  display: flex;
}

.ant-form-item {
  // margin-top: 20px;
  margin-bottom: 30px;
}
.fastDataIcons{
  border: 1px solid #d9d9d9;
  padding: 8px;
  border-left: none;
  color: #1990ff;
  border-radius: 4px;
  margin-top: 0px   !important;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
@primary-color: #3875FE;