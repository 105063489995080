.ant-card {
  border-radius: 10px;
}

.ant-card-head {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ant-descriptions-item-content {
  white-space: nowrap;
}

.ant-descriptions-item {
  margin-right: 10px;
}

.ant-space-align-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.ant-space-item {
  width: 100%;
}

.content {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.ant-spin-nested-loading>div>.ant-spin-lg .ant-spin-text {
  padding-top: 16px !important;
  font-size: 18px;
  font-weight: 600;
}
.detailPage {
  padding: 10px;
  background-color: #fff;
}
@primary-color: #3875FE;