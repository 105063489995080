.FininfoBox{
    overflow: hidden;
    .leftForm{
        float: left;
        width: 36%;
        border-right: 1px solid #f2f2f2;
        .priceBox{
            font-size: 18px;
            color: red;
            font-weight: 700;
            margin-top: 30px;
            margin-left: 16%;
        }
    }
    .rightInfo{
        float: right;
        width: 60%;
    }
}
@primary-color: #3875FE;