.GMVNotificationBox {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  font-size: 20px;
  width: 100%;
  .bgImg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .countBox {
    position: absolute;
  }
  .gmvAmountBox {
    color: #fff;
    top: 30%;
    font-size: 9rem;
    text-align: center;
    width: 100%;
    font-weight: 600;
    -webkit-text-stroke: 4px #ffd5c3;
    background-image: -webkit-linear-gradient(bottom, red, #fc5223, yellow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    span {
      font-size: 0.4em;
      -webkit-text-stroke: 2px #ffd5c3;
    }
  }
  .gmvCountBox {
    color: #feb903;
    top: 52%;
    font-size: 4em;
    font-weight: 900;
    text-align: center;
    width: 100%;
    font-weight: 600;
    -webkit-text-stroke: 4px #ffe5a2;
    span {
      font-size: 0.3em;
      font-weight: 100;
      margin-left: 15px;
      -webkit-text-stroke: 2px #ffe5a2;
    }
  }
  .clockBox {
    color: #ffffc9;
    top: 72%;
    font-size: 1.4em;
    font-weight: 500;
    text-align: center;
    // width: 100%;
    // left: 39%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 14.5em;
    // width: 26rem;
    // left: 32.4rem;
    padding: 20px;
    overflow: hidden;
    background-image: url("https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/timeBox.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    div {
      margin: 0 8px;
      float: left;
      span {
        float: left;
        display: block;
        margin-right: 4px;
        // padding: 0 8px;
        width: 1.2em;
        background-image: url("https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/timeSBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    p{
        margin-bottom: 0;
    font-size: 0.6em;
    }
  }
}

@primary-color: #3875FE;