.content {
  margin-top: 20px;
  padding: 0 10px;
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 0 0;
}
.finance_micro__div {
  margin-top: 20px;
  padding: 10px 20px;
  border-bottom: 1px solid #e4e4e4;
  z-index: 10;
  width: 100%;
  height: 90%;
  background: #f0f2f5;
  micro-app{
    height: 100%;
  }

  .footerBox {
    background: #fff;
    padding: 10px;
    border-bottom: 2px solid #f0f2f5;

    span {
      margin-right: 20px;
    }
  }
}

@primary-color: #3875FE;