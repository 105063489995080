.paltformMeetingBox{
    overflow: hidden;
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    h3{
        text-align: center;
        font-size: 19px;
        margin-bottom: 20px;
        span{
            font-size: 17px;
        }
        .level4{
            color: #efb4b4;
        }
        .level3{
            color: #f2b782;
        }
        .level2{
            color: #f2997e;
        }
        .level1{
            color: #ea733d;
        }
        .level0{
            color: #ef0707;
        }
    }
    .priceNervous{
        overflow: hidden;
        background-color: rgba(238, 238, 238, .4);
        padding-top: 20px;
        margin-bottom: 20px;
    }
    ul{
        width: 50%;
        float: left;
        border-right: 1px solid #e3e3e3;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        li{
            width: 50%;
            float: left;
            margin-bottom: 10px;
        }
        .priceBox{
            text-align: right;
        }
    }
    ul:last-child {
        border: none;
      }
}
.fundingSummaryBox{
    .fundingSummaryTitleBox{
        background: #fff;
        margin-top: 4px;
        padding: 12px;
        p{
            font-size: 20px;
            margin-bottom: 0px;
        }
        span{
            color: #999;
            font-size: 10px;
        }
    }
    .fundingSummaryContentBox{
        overflow: hidden;
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
        ul{
            overflow: hidden;
            li{
                float: left;
                width: 32.4%;
                border-radius: 4px;
                box-shadow: 0px -1px 9px 0px #e4e4e4;
                background: #fff;
                padding: 20px;
                margin-right: 1rem;
                .title{
                    overflow: hidden;
                    .titieLeft{
                        float: left;
                        width: 50%;
                        p{
                            margin-bottom: 0;
                        }
                        span{
                            color: #999;
                            font-size: 10px;
                        }
                    }
                    .titleRight{
                        float: right;
                        width: 36%;
                        text-align: right;
                        color: #999;
                        font-size: 10px;
                    }
                }
                .priceBox{
                    overflow: hidden;
                    font-size: 22px;
                    font-weight: 500;
                    span{
                        font-size: 10px;
                    }
                    .telescopingBtn{
                        float: right;
                    }
                }
                .progressBox{
                    overflow: hidden;
                    margin: 10px 0;
                    height: 12.4rem;
                    .progressCircle{
                        width: 12rem;
                        height: 12rem;
                        position: relative;
                        border-radius: 50%;
                        float: left;
                        .ab{
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }
                        .circle_left{
                            border: 1.4rem solid #deeaff;
                            border-radius: 50%;
                            clip: rect(0,4rem,12rem,0);
                            // &:after {
                            //     display: block;
                            //     content: '';
                            //     width: 1.4rem;
                            //     height: 1.4rem;
                            //     border-radius: 50%;
                            //     background: #6292fe;
                            //     position: absolute;
                            //     left: 2rem;
                            //     top: -1rem;
                            // }
                            // &:before {
                            //     display: block;
                            //     content: '';
                            //     width: 1.4rem;
                            //     height: 1.4rem;
                            //     border-radius: 50%;
                            //     background: #6292fe;
                            //     position: absolute;
                            //     left: 1.9rem;
                            //     top: 8.84rem;
                            // }
                        }
                        .circle_right{
                            border: 1.4rem solid #6292fe;
                            border-radius: 50%;
                            clip: rect(0,12rem,12rem,4rem);
                        }
                        .circle_text{
                            height: 100%;
                            text-align: center;
                            p{
                                margin-bottom: 0;
                                margin-top: 4rem;
                                font-size: 1.7rem;
                            }
                            span{
                                font-size: 10px;
                                color: #999;
                                display: block;
                            }
                        }
                    }
                    .level4{
                        .circle_left{
                            clip: rect(0rem, 10rem, 12rem, 0);
                            
                        }
                        .circle_right{
                            border-color: #4ac672;
                            clip: rect(0,12rem,12rem,10rem);
                        }
                        p{
                            color: #4ac672;
                        }
                    } 
                    .level3{
                        .circle_left{
                            clip: rect(0, 7rem, 12rem, 0);
                        }
                        .circle_right{
                            border-color: #9ab743;
                            clip: rect(0, 12rem, 12rem, 7rem);
                        }
                        p{
                            color: #9ab743;
                        }
                    }
                    .level2{
                        .circle_left{
                            clip: rect(0,5rem,12rem,0);
                        }
                        .circle_right{
                            border-color: #dcaf25;
                            clip: rect(0,12rem,12rem,5rem);
                        }
                        p{
                            color: #dcaf25;
                        }
                    }
                    .level1{
                        .circle_left{
                            clip: rect(0,4rem,12rem,0);
                        }
                        .circle_right{
                            border-color: #fc4e4e;
                            clip: rect(0, 12rem, 12rem, 2rem);
                        }
                        p{
                            color: #fc4e4e;
                        }
                    }
                    .level0{
                        .circle_left{
                            clip: rect(0,4rem,12rem,0);
                        }
                        .circle_right{
                            border-color: #da2d29;
                            clip: rect(0,12rem,12rem,1rem);
                        }
                        p{
                            color: #da2d29;
                        }
                    }
                    .progressContent{
                        width: 100%;
                        text-align: center;

                    }
                    .progressPrice{
                        width: 40%;
                        float: right;
                        font-size: 10px;
                        margin-top: 4rem;
                        span{
                            width: 8px;
                            height: 8px;
                            display: block;
                            float: left;
                            border-radius: 50%;
                            background-color: #deeaff;
                            margin-top: 6px;
                            margin-right: 6px;
                        }
                        p{
                            font-size: 18px;
                            font-weight: 500;
                            margin-bottom: 0;
                            margin-left: 14px;
                        }
                        .blueColor{
                            background: #6292fe;
                        }
                    }
                }
                .telescopingBox{
                    width: 100%;
                    ul{
                        overflow: hidden;
                        width: 100%;
                        li{
                            width: 47%;
                            float: left;
                            margin-right: 0;
                            padding: 10px;
                            
                            background: rgba(225, 233 ,254 , .34);
                            // background-image:linear-gradient(#e1e9fe,#fff);
                            .bigFont{
                                font-weight: 500;
                            }
                            .fontPrice{
                                font-size: 16px;
                            }
                            p{
                                color: #6292fe;    
                                // font-size: 10px;
                                margin-top: 10px;
                                border-bottom: 1px solid #eee;
                                padding-bottom: 10px;
                                cursor: pointer;
                                span{
                                    float: right;
                                    color: #000;
                                    font-weight: 500;
                                }
                            }
                            p:last-child{
                                border: none;
                            }
                        }
                        li:last-child{
                            margin-left: 1.2rem;
                        }
                    }
                }
            }
           li:last-child{
            margin-right: 0;
           }
        }
    }
}
@primary-color: #3875FE;