
.settlement_modal{
  .ant-drawer-body{
    padding: 0 24px;
    .container{
      .header_line{
        font-size: 12px;
        font-weight: bold;
        margin: 10px 0;
      }
    }
  }
.ant-drawer-footer{
  .ant-space{
    width: 100%;
    justify-content: flex-end;
    .ant-space-item{
      width: unset;
    }
  }
}
  .ant-drawer-header{
    .ant-drawer-extra{
      margin-right: 24px;
    }
  }
.header_btn{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 0 24px 0;
}
  .settlement_table{
    .ant-table-cell{
      padding: 2px 8px;
    }
    .ant-table-row{
      font-size: 12px;
    }
    .editable-cell-value-wrap{
      .editIcon{
        visibility: hidden;
      }
      &:hover{
        cursor: pointer;
        .editIcon{
          visibility: visible;
        }
      }
    }
  }
}

@primary-color: #3875FE;