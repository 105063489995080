.priceArrange{
  .ant-form-item-control{
    .ant-form-item-control-input-content{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .ant-form-item{
        margin-bottom: 0;
      }
    }
  }
}

@primary-color: #3875FE;