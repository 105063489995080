.title {
    font-size: 18px;
    font-weight: 700;
}
.content {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    border-top: 1px solid #e9e9e9;
}
.contentItem {
    display: flex;
    width: 50%;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #f0f0f0;
}
.contentItemLabel {
    width: 20%;
    background-color: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    padding: 0 10px;
}
.contentItemValue {
    width: 80%;
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    padding: 0 10px;
}
.btns {
    padding: 0 20px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.btnItem {
    margin: 0 0 0 10px;
}

@primary-color: #3875FE;